import React from "react"

export default (props) => {
  return (
    <div>
    <ul>
      <li>
        Title: {props.title}
      </li>
      <li>
        Recorded: {props.recordedDate} in {props.recordedLocation} by {props.engineers}
      </li>
      <li>
        Released: {props.releaseDate} by {props.recordLabel} ({props.catalogNumber}), available as {props.formatsAvailable}
      </li>
    </ul>

    </div>
  )
}

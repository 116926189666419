import React from "react"
import { Link } from "gatsby"
import YazanHeaderLogo from "../images/yazan-header-logo.png"

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

export default (props) => {
  let env = props.env;
  // env = "production";
  const development = (env === "development");

  return (
    <header style={{ marginBottom: `1.5rem` }}>
      <Link to="/" style={{ textShadow: `none`, backgroundImage: `none` }}>
        <img alt="Yazan Header Logo" style={{ display: `inline` }} src={YazanHeaderLogo} />
      </Link>
      {development && (
        <ul style={{ listStyle: `none`, float: `right` }}>
          <ListLink to="/">Home</ListLink>
          <ListLink to="/hahaha/">Hahaha</ListLink>
          <ListLink to="/howlin-ep/">Howlin EP</ListLink>
          <ListLink to="/undress-my-mind/">Undress My Mind</ListLink>
          <ListLink to="/your-crooked-part/">Your Crooked Part</ListLink>
        </ul>
        )
      }

    </header>
  )

}

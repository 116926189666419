import React from "react"
import "../styles/album-header.css"

export default (props) => {
  const cover = props.cover
  return (
    <div>
      <img className="album-cover" src={cover} alt="Album Cover"/>
    </div>
  )
}

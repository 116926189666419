import React from 'react'
import { css } from "@emotion/core"

export default () => {
  return (
    <>
    <div
    css={css`
      margin: 0 auto;
      max-width: 850px;
      `}
      >

      <p>Right now I'm rebuilding this site. Listen to my records below while I cook it up</p>
      <iframe title="Hahaha" style={{border: 0, width: `350px`, height: `786px`}} src="https://bandcamp.com/EmbeddedPlayer/album=3684881630/size=large/bgcol=333333/linkcol=9a64ff/transparent=true/" seamless><a href="http://music.yazan.co/album/hahaha">Hahaha by Yazan</a></iframe>
      <iframe title="Howlin EP"style={{border: 0, width: `350px`, height: `786px`}} src="https://bandcamp.com/EmbeddedPlayer/album=3735900033/size=large/bgcol=333333/linkcol=9a64ff/transparent=true/" seamless><a href="http://music.yazan.co/album/howlin">Howlin&#39; by Yazan</a></iframe>
      <iframe title="Undress My Mind" style={{border: 0, width: `350px`, height: `786px`}} src="https://bandcamp.com/EmbeddedPlayer/album=3130403542/size=large/bgcol=333333/linkcol=9a64ff/transparent=true/" seamless><a href="http://music.yazan.co/album/undress-my-mind">Undress My Mind by Yazan</a></iframe>
      <iframe title="Your Crooked Part" style={{border: 0, width: `350px`, height: `786px`}} src="https://bandcamp.com/EmbeddedPlayer/album=905428688/size=large/bgcol=333333/linkcol=9a64ff/transparent=true/" seamless><a href="http://music.yazan.co/album/your-crooked-part">Your Crooked Part by Yazan</a></iframe>
      </div>

      </>
    )
  }

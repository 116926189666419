import React from "react"
import AlbumMetaInfo from "../components/album-meta-info"
import Layout from "./layout"
import AlbumCover from "../components/album-cover"

export default (props) => {
  return (
    <Layout location={props.location}>
      <AlbumCover cover={props.albumCover} />
      <AlbumMetaInfo {...props}/>
    </Layout>
  )

}

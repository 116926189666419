import React from "react"
import SiteMetadata from "../components/site-metadata"
import Maintenance from "../components/maintenance"
import Header from "../components/header"
import "../styles/layout.css"

export default (props) => {
  const { location, children } = props;
  let env = process.env.NODE_ENV;
  // env = "production"
  const development = (env === "development");
  const production = (env === "production");
    return (
      <>
        <SiteMetadata pathname={location.pathname} />
        <div style={{ margin: `3rem auto`, maxWidth: 850, padding: `0 1rem` }}>
          <Header env={env}/>
          {production && (
            <Maintenance />
          )}
          {development && (
            <>
              {children}
            </>
          )}

      </div>
      </>
    )
  }
